.aboutUs{
    padding-top: 10px;
    direction: rtl;
    background-color: ghostwhite;
}
.container-about{
    display: grid;
    grid-template-columns: repeat(3, 25%);
    text-align: center;
    align-items: stretch;
    justify-content: space-evenly;
    margin: -90px auto 0px;
    padding-bottom: 50px ;
}
 .ppp{
    font-size: 20px;
    font-family:'Fredoka', sans-serif;
  }
 
.icon-about{
    font-size: xx-large;
    margin-inline: 40px;
    margin-bottom: 30px;
    
    padding: 10px;
    border-radius: 50%;
    background-color: white;
    width: 70px;
    

}
.i1{
    color: #379dbf;
}
.i2{
    color: rgb(21 31 95);
}
.about1{
    background-color: #379dbf;  
}
.about2{
    background-color: rgb(21 31 95);
}
.Paragraph{
    display: flex;
    /* font-family:'Fredoka', sans-serif; */
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    margin: 10px;
    color: white;
    border-radius: 30px;
    align-items: center;
}
.photo-about{
    border-radius: 10px;
    width: 300px;
    float: left;
    margin-left: 60px;
}
@media screen and (max-width: 980px){
    
    .photo-about{
        width: 150px;
    }
    
   
}
@media screen and (max-width: 850px){
    .container-about{
        display: list-item;
    }
    .title-service{
        padding-top: 90px !important;
    }
}

