.header-home{
   text-align: center;
    font-weight: bold;
}
.main-title{
   font-family: 'Rubik Dirt', cursive;
   font-size: 400%;
   color: #007bff;
}

.img-soldier{
   width: 50%;
   height: 300px;
   border-radius: 20px;
}
.advantages-div{
   /* background-color: #bff4ff; */
   padding-bottom: 50px;
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   align-items: center;
   color: #007bff;
}
.services-div{
   /* background-color: #ed8c8c; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    color: rgb(239, 72, 72);
/*  
    background: linear-gradient(#bff4ff 5%, #ed8c8c 90%) ; */
}
.home{
    direction: rtl; 
    /* font-family: 'Fredoka', sans-serif;  */
 }
 .title-add-elevator{
   text-align: center;
   font-family:'Fredoka', sans-serif;
   padding-top: 40px;
 }
 .advantages{
   display: grid;
   grid-template-columns: repeat(2, 43%);
   justify-content: center;
   justify-items: stretch;
}
.advantage{
   direction: rtl;
   border-bottom: 1px solid #101266;
   border-top: 1px solid #101266;
   padding: 25px;
   margin: 18px;
   text-align: center;
   /* font-family: 'Fredoka', sans-serif; */
   box-shadow: 0 10px 10px rgb(0 0 0 / 25%);
   border-radius: 20px;
   color: white;
   background-color: #007bff;
}
.title-all-services{
   padding-top: 50px;
   text-align: center;
   font-family:'Fredoka', sans-serif;
}
.grid-services{
   display: grid;
   grid-template-columns: repeat(3, 30%);
   justify-content: center;
   justify-items: center;
}
.service{
   padding: 20px;
   margin: 28px;
   text-align: center;
   font-weight: bold;
   border-left: 1px solid #101266;
   border-right: 1px solid #101266;
   border-radius: 20px;
   width: 70%;
   box-shadow: 0 10px 10px rgb(239 72 72 / 25%);
   background-color: rgb(239, 72, 72);
}
.text-service{
   text-decoration-line: none;
   color: white
   ;
   font-size: large;
   font-weight: bold;
   border: 0px;
   background-color: rgb(239, 72, 72);
}
.service_title{
   text-align: center;
}
.emoji{
   font-size: xx-large;
}

@media screen and (max-width: 640px){
   .advantages{
      grid-template-columns: repeat(2, 50%) ;
   }
   .grid-services{
      grid-template-columns: repeat(2, 54%);
   }
}
@media screen and (max-width: 500px){
   .header-home{
      padding-top: 10%;
   }
}
 .photo-home{
    width: 100%;
    height: 100%;
 }
 .part2{
    width: 50%;
    
 }
 .advantages-title{
    text-align: center;
    /* font-family: 'Fredoka', sans-serif; */
    font-weight: bold;
    color: midnightblue;
    
 }
 
 
 .text-home{
    /* font-family: 'Suez One', serif; */
    text-align: center;
    direction: rtl;
    margin: 20px;
    color: white;
 }
 
 .column{
    /* height: 400px; */
    display: -webkit-box;
    background-color:#101266;
 }
 .title-home{
    /* font-family: 'Suez One', serif; */
    padding-top: 5%;
    margin-right: 40%;
    padding-right: 5%;
    color: #7bcef4;
 
 }
 
 .column1{
    padding-top: 40px;
    padding-bottom: 40px;
 }
 .column2{
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background-color:#bff4ff;
    /* font-family: 'Fredoka', sans-serif; */
    margin-bottom: 15px;
    
 }
 .column3{
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    /* font-family: 'Fredoka', sans-serif; */
 }
 .document{
    padding: 5px;
 }
 
 
 
 
 .serv1{
    box-shadow: 15px 10px 10px rgb(0 0 0 / 25%);
    border-radius: 7px;
    padding: 20px;
    margin: 28px;
    text-align: center;
    font-weight: bold;
    justify-content: space-around !important;
    
 }
 
 .scroll-container{
    overflow: hidden;
    font-family:'Fredoka', sans-serif;
 }
 .documents-list{
    display: grid;
    grid-template-columns: repeat(3, 23%);
    justify-content: center;
 }
 .column-places{
    padding: 40px;
     height: 800px;
     margin: 10px;
    /* position: fixed; 
   min-width: 100%;*/
   min-height: 100%;
   background-size: contain;
   background-position: center;
 }
 .places-list{
    display: flex;
    justify-content: space-evenly;
    position: sticky;
    padding-top: 70px;
    
 }
 .places{
    font-weight: bold;
    font-family: 'Suez One', serif;
 }
 @media screen and (max-width: 1140px){
    
    .part2{
     padding-top: 20px;
     width: 40%;
     
    }
    
 }
 @media screen and (max-width: 920px){
    .photo-home{
       width: 100%;
       height: 130%;
       margin: 0px;
    }
    .part1{
       display: contents;
    }
    .part2{
       background-color: #ffffffcf;
     position: absolute;
     top: 50%;
     width: auto;
     height: auto;
     
    }
    .text-home{
       color: #101266;
    }
 
    .title-home {
       color: #1d77a1;
       text-align: center;
       padding-top: 0;
    margin-right: 0;
    padding-right:0;
    }
    
    .column-places{
       height: 300px;
    }
 }
 @media screen and (max-width: 850px){
    .places-list{
       padding-top: 20px;
    }
 }
 @media screen and (max-width: 750px){
    .part2{
       top:20%;
    }
    .img-soldier{
      width: 80%;
    }
    
 }
 @media screen and (max-width: 640px){
    
 
    .part2{
       top:15%;
    }
    .places-list{
       display: grid;
     grid-template-columns: repeat(2,50%);
     justify-items: center;
    }
 }
 @media screen and (max-width: 540px){
   .img-soldier{
      height: 200px;
   }
 }
 
 .scroll-text {
    text-align: left;
   /* animation properties */
   width: 1000px;
   font-size: large;
   animation: my-animation 45s linear infinite;
 }
 /* for Firefox */
 @-moz-keyframes my-animation {
   from { -moz-transform: translateX(100%); }
   to { -moz-transform: translateX(-100%); }
 }
 /* for Chrome */
 @-webkit-keyframes my-animation {
   from { -webkit-transform: translateX(-100%); }
   to { -webkit-transform: translateX(100%); }
 }
 @keyframes my-animation {
   from {
     -moz-transform: translateX(-100%);
     -webkit-transform: translateX(-100%);
     transform: translateX(-100%);
   }
   to {
     -moz-transform: translateX(100%);
     -webkit-transform: translateX(100%);
     transform: translateX(100%);
   }
 }