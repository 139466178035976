.Navbar{
    background-color: rgb(239, 72, 72)!important;
    height: 40px;
    width: 100%;
    z-index: 1;
    display: block;
    justify-content: right;
    align-items: center;
    /*font-size: 1.2rem;
    font-size: 20px;*/
    position: sticky;
    width: 100%;
    top: 0;
    box-sizing: inherit;
    font-family:'Fredoka', sans-serif;
    line-height: 2.5;
    box-shadow: 0 10px 10px rgb(0 0 0 / 25%);
    
}
.navbar-container{
    color: #000;
    display: flex;
    
    
}
.maof-logo{
    width: 300px;
    /* height: 30%; */
    /* height: 200px; */
    direction: rtl;
    position: absolute;
    right: 0px;
    /* top: -65px; */
    display: flex;
    cursor: pointer;
     
}


.nav-menu{
    display: grid;
    grid-template-columns: repeat(7,2.5cm);
    /* grid-gap: 15px; */
    list-style: none;
    text-align: center;
    /* width: 40vw; */
    /* justify-content: left; */
    position: absolute;
    /*padding-top: 20px;*/
    direction: rtl;

}
.nav-item{
    height: 40px;
}
.nav-links{
    color: rgb(15, 15, 17);
    /* display: flex; */
    align-items: center;
    text-decoration: none;
    padding: 0.2rem;
    padding-left: 20px;
    height: 100%;
    /* font-family: 'Suez One', serif; */
    
    /*font-size: 20px;*/


}
.dropdown-menu{
    direction: rtl;
}

 .nav-links:hover{
    color: #433f3f;
    /* font-size: 20px; */
}
/*.nav-links-1:hover{
    color: #000;
} */
.fa-bars{
    color: black;
    /* display: flex;
    left: 10px;
    top: 15px;
    position: absolute; */
    font-size: xx-large;
}
.nav-links-mobile{
    display: none;

}
.menu-icon{
    display: none;
}
@media screen and (max-width: 1010px){
    .maof-logo{
        width: 200px;
    }
}


@media screen and (max-width: 900px){
    .NavbarItems{
        position: relative;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 150px;
        height: 70vh;
        position: absolute;
        top: 90%;
        right: 130%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active{
        background: rgb(239, 72, 72);
        right: 70%;
        transition: all 0.5s ease;
        justify-content: flex-start;
        width: 30%;
        /* justify-self: left;
        top:0px; */
        padding-top: 20px;
        position: initial;
        display: flex;
        align-items: center;
    }
    .nav-links {
        text-align: center;
        padding: 1rem;
        /* width: 70%; */
        display: table;
        /*font-size: 13px;*/
        
      }
      .nav-links:hover {
        background-color:none;
        /* font-size: 15px; */
        border-radius: 0;
        /* height: 20%;
        width: 50%; */
      }
    .maof-logo{
        position: absolute;
        top: 0;
        
      
    }
    .menu-icon{
        color: black;
        display: block;
        position: fixed;
        top:-59px;
        left: 50px; 
        transform: translate(100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        z-index: 1;
        

    }
    .fa-times{
        color: black;
        font-size: 2rem;
        /* display: block; */
    }
    
   

}
@media screen and (max-width: 580px){
    .nav-menu.active{
        right: 60%;
        width: 50%;
    }
}