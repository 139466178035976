.user{
    padding-top: 10%;
    background-color: aliceblue;
    font-family:'Fredoka', sans-serif;
}


@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.user{
    direction: rtl;
    text-align: center;
}
.container{
    margin-top:50px;
    margin-bottom: 50px;
    
    padding: 0px;
}
.card{
    position: relative;
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.10rem;
}

.track{
    position: relative;
    background-color: #ddd;
    height: 7px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 60px;
    margin-top: 50px;
}
.track .step{
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 25%;
    margin-top: -18px;
    text-align: center;
    position: relative;
}
.track .step.active:before{
    background: #355fdb;
}
.track .step::before{
    height: 7px;
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: 18px;
}
.track .step.active .icon{
    background: #355fdb;
    color: #fff;
}
.track .icon{
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: relative;
    border-radius: 100%;
    background: #ddd;
    
}
.track .step.active .text{
    font-weight: 400;
    color: #000;
}
.track .text{
    display: block;
    margin-top: 7px;
}
.box{
    text-align: right;
    width: 75%;
    padding: 26px;
    height: auto;
    box-shadow: 0 5px 3.6px 0.4px rgba(0,0,0,.09803921568627451);
    background-color: #fff;
    border-radius: 26px;
    list-style: none;
    display: inline-table;
    align-items: center;
    flex-direction: column-reverse;
}
.no_show_step{
    display: none;
}
.show_step{
    display: block;
}
.explan ul li {
    padding: 25px 15px;
    border-bottom: 1px solid #ddd;
}
@media screen and (max-width: 490px){
    .track .text{
        font-size: x-small;
    }
}