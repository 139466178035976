
.container-us{
    padding-top: 5%;
    /* font-family: 'Fredoka', sans-serif; */
}
.contact-title{
  text-align: center;
  color: rgb(21 31 95);
  font-weight: bold;
  
}
.contact-title2{
  text-align: center;
  direction: rtl;

}
.row-contact{
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  justify-items: center;
  padding-top: 40px;


}
.Filling-in-details{
  /* background-color: #bff4ff; */
    border: rgb(21 31 95) solid 3px;
    text-align: center;
    padding: 10px 0px;
    /* border-radius: 10%; */
    box-shadow: 0 50px 50px rgb(0 0 0 / 25%);
    display: grid;
    text-align: center;
    align-items: center;
    position: relative;
    direction: rtl;
    margin: 15px;
}
.form-contact{
  display: grid;
  justify-content: space-around;
  justify-items: center;
}

.contact-image{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
 
.contactInfo{
  /* display: flex;
  flex-direction: column;
  align-items: flex-end; */
  text-align: center;
  border-bottom: 10px solid rgb(239, 72, 72);
  border-left: 10px solid rgb(239, 72, 72);
  border-radius: 30%;
  object-fit: cover;
  margin: 30px 60px;
  padding: 40px 30px;
  
 
}
.map-section{
  display: inline-block;
  margin: 30px;
}
.map-section #gmap_canvas{
  height: 55vh;
  width: auto;
  border-radius: 5px;
}
.email{
  color: black;
  text-decoration: none;
  font-size: large;
}
.phone{
  color: black;
  text-decoration: none;
  font-size: x-large;
}
.info{
  padding: 15px;
}


.title-contact{
  font-weight: bold;
  color: rgb(21 31 95);
 
}
.input-contact{
  text-align: center;
  border: 1px solid rgba(2, 39, 103, 0.733);
  margin: 5px;
  padding: 10px 5px;
  width: auto;
}
.input-contact-number{
  text-align: center;
  border: 1px solid rgba(2, 39, 103, 0.733);
  margin: 5px;
  padding: 10px 5px;
  width: 92px;
}
.inputs-contactus{
  display: grid;
  grid-template-columns: repeat(3, 5cm);
  justify-content: space-between
  
}
/* .p-contact{
  font-family: 'Fredoka', sans-serif;
} */
.send-btn{
  margin: 10px;
  background: #e02121eb;
  width: 80px;
  height: 35px;
  border: 1px solid;
  border-radius: 10px;
  
  font-size: x-large;
}
.label-contact{
  color: rgb(21 31 95) ;
}
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 70px; /* 30px from the bottom */
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
 
  
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 70px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 70px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 70px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 70px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @media screen and (max-width: 794px){
    .row-contact{
      display: flex;
      flex-direction: column-reverse;
      align-content: flex-end;
      flex-wrap: nowrap;;
      align-items: center;
    }
    .contactInfo{
      padding: 80px 40px;
    }
  }
 @media screen and (max-width: 980px){
 
  .container-us{
    padding-top: 10%;
  }

}
@media screen and (max-width: 640px){
  .container-us{
    padding-top: 25%;
  }
}

@media screen and (max-width: 620px){
  .inputs-contactus{
    grid-template-columns: repeat(1, 85%);
    justify-content: space-around;
  }
}
 @media screen and (max-width: 480px){
    .map-section #gmap_canvas{
      height: auto;
    }
  }
/*
@media screen and (max-width: 670px){
  .row-contact {
    min-height: 520px;
    min-width: 390px;
  }
  .Filling-in-details {
    width: 250px;
    padding: 0px 0px;
    margin-right: -20%;
  }
  .contactInfo{
    left: -49px;
  }

}
@media screen and (max-width: 640px){
  .container-us{
    padding-top: 25%;
  }
}
@media screen and (max-width: 630px){
 
  .title-contact {
    font-size: 15px;
  }

}
@media screen and (max-width: 470px){
  .container-us{
    padding-top: 35%;
  }
}
@media screen and (max-width: 450px){
  .contact-image{
    width: 400px;
  }
  
  .title-contact{
    font-size: 20px;
  }
  
  .p-contact{
    font-size: 11px;
  }
  .contactInfo{
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 400px){
  
  
  .Filling-in-details{
    margin-right: -70%;
    
  }
  .contact-image {
    width: 420px;

  }
  .box-phone{
    width: 100px;
  }
} */