.allCalls{
    padding-top: 10%;
}
.tCalls{
    direction: rtl;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 960px){
    .allCalls{
        padding-top: 20%;
    }
}