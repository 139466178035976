
.call_fault{
    padding-top: 10%;
    font-family: 'Suez One', serif;
    direction: rtl;
    text-align: center;
}
.fault_table{
    direction: rtl;
    align-items: center;
    display: inline-block;
}

@media screen and (max-width: 960px){
    .call_fault{
        padding-top: 20%;
    }
    th{
        font-size: 12px;
    }
}