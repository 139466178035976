.footer-container{
    background-color: #969ca2;
    direction: rtl;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.facebook{
    color: rgb(21 31 95);
    font-weight: bold;
    text-decoration: none;
    
}
.fa-facebook-f{
    color: white;
    border: 1px solid;
    border-radius: 50px;
    padding: 10px 12px;
    background-color: darkblue;
    margin: 5px;
    position: relative;
    z-index: 1;
    /* display: inline-block; */
}
.places_list{
    background-color: lightgray;
    display: grid;
    grid-template-columns: repeat(3,25%);
    justify-content: center;
    padding: 20px;
}
.place-footer{
    text-decoration-line: none;
    color: crimson;
}