.process{
    padding-top: 10px;
    direction: rtl;
    background-color: ghostwhite;
    /* font-family:'Fredoka', sans-serif; */

}
.a-process{
    text-decoration: none;
}
.title-process{
    font-weight: bold;
    color: rgb(21 31 95);
    text-align: center;
} 
.txt-process{
    margin: -90px auto 0px;
    text-align: center;
    color: rgb(21 31 95);
    
    padding: 0px 10%;
}   
.stages{
    padding: 0px 12%;
}

.stage{
    padding-bottom: 8px;
}

@media screen and (max-width: 500px){
    .process{
        padding-top: 10%;
    }
}