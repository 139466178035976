
.services{
    margin: 0;
    padding: 0;
    direction: rtl;
    padding-top: 10px;
    background-color: ghostwhite;
    font-family:'Fredoka', sans-serif;
    min-height: 100vh;
    text-align: center;
}

.title-service{
    color: rgb(21 31 95);
    font-weight: bold;
    text-align: center;
    /* font-family:'Fredoka', sans-serif; */
    background-color: #c6d4ef;
    height: 250px;
    padding-top: 100px;
}
.accordion_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 25px;
    align-items: center;
    padding-bottom: 20px;
  }
  .accordion_item {
    display: flex;
    flex-direction: column;
    width: 750px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    border-top: 4px solid transparent;
  }
.header {
    display: flex;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    padding: 1rem 2rem;
  }
  .header:hover {
    background: #e7eaed;
  }
  
  .title {
    flex: 1;
    font-size: 40px;
  }

.accordion-body{
    padding: 0 2rem;
    overflow: hidden;
    height: 0;
  
}
.accordion-body p{
    font-size: 30px;
    padding: 1rem 2rem;
    list-style-type: circle;  
    /* background-color: #c6d4ef; */
}
.open{
    border-color: #007bff;;
}
.open .header{
    color: #007bff;;
}
.open .accordion-body{
    height: auto;
}
@media screen and (max-width: 770px){
    .accordion_item{
        width:400px;
    }
}
@media screen and (max-width: 700px){
    .accordion{
   
        padding:20px 50px ;
    }
}