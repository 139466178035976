.service-tracking{
    direction: rtl;
}
.add_new_project{
    direction: rtl;
}
.list-place{
    /* direction: rtl; */
    /* align-items: center; */
    display: grid;
    grid-template-columns: repeat(3, 33%);
    justify-content: center;
    justify-items: stretch;

    
}
.btn-ap{
    margin-right: 40px;
    border-radius: 10px;
}

.values{
    /* border-bottom: 2.5px solid #323d3d; */
    width: 70%;
    justify-content: center;
    padding: 15px;
}
.place{
    color: blueviolet;
    font-size: 30px;
    font-family: 'Suez One', serif;
    border: none;
    background-color: white;

}
/*
.btns-add-del{
    padding: 8px 10px ;
}*/
.graphh{
    width: auto;
    padding-top: 10%;

}
.title_graph{
    font-family: 'Suez One', serif;
    text-align: center;
}
.btn-delete{
    margin-right: 180px;
    border-radius: 10px;
}
.btns-add-del{
    padding-bottom: 5px;
}
.btn-fault{
    border-radius: 10px;
    margin-right: 5px;
}
.btn-add{
    margin-right: 40px;
    border-radius: 10px;
}
.btn-ST{
    font-family:'Fredoka', sans-serif;
    border-radius: 10px;
    font-weight: 600;
    margin-right: 150px;
    font-weight: 600;
    width: 80px;
    margin-top: 10px;
    border-radius: 10px;
}
.twoBTN{
    margin-left: auto;
    margin-right: auto;
}
.btn-treat{
    margin-left: 30%;
    font-family: 'Suez One', serif;
    font-size: 20px;
    color: darkslateblue;
}
.grid-btn{
    display: flex;
    text-align: center;
    justify-content: space-evenly;
    /* text-center d-flex justify-content-evenly */
}
@media screen and (max-width: 960px){
    
    .btn-delete {
        margin-right: 80px;
    }
    .twoBTN {
        direction: rtl;
    }
}
@media screen and (max-width: 550px){
    .service-tracking{
        padding-top: 10%;
    }
}
@media screen and (max-width: 480px){
    .btn-add {
        margin-right: 0px;
    }
    .btn-delete {
        margin-right: 5%;
    }
    /* .service-tracking{
        padding-top: 35%;
    } */
}
@media screen and (max-width: 750px){
    .list-place{
        grid-template-columns: repeat(2, 50%);
    }
}
@media screen and (max-width: 420px){
    .list-place{
        grid-template-columns: repeat(1, 100%);
    }
    .grid-btn{
        display: inherit;
    }
}
