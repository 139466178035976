.customer-card{
    
    width: 100%;
    padding: 10px;
    display: inline-grid;
    /* justify-content: center; */
    position: relative;

}
.delete-btn{
    position: absolute;
    top: 0px;
    left: 10px;

}
.customer-title{
    text-align: center;
    padding-top: 25px;
}
.customer-num{
    text-align: center;
}
.update-btn{
    text-align: center;
}