.accessibility{
    position: fixed;
    bottom: 10px !important;
    z-index: 1;
    left: 5px;
}
.close-acc{
    position: absolute;
    display: block;
    font-size: 20px;
    background-color: #ffffff00;
    border-radius: 50%;
    /* padding: 5px; */
    margin: 10px;
    cursor: pointer;
}
.img-accessible{
    width: 70px;
    box-shadow: 10px 0px 10px rgb(0 0 0 / 50%);
    border: 4px solid rgb(240 27 27);
    border-radius: 50%;
}
.tools-list{
    position: relative;
   
}
.no-display{
    display: none;
}
.display{
    height: 300px;
    cursor: pointer;
    z-index: 1;
    padding: 10px 15px;
    
    top: 150px;
    justify-content: right;
    overflow: scroll;
    /*position: fixed;right: 0;*/
    text-align: right;
    background-color: white;
    list-style: none;
    display: block;
    direction: rtl;
    transition: left .4s ease-in,right .4s ease-in,top .4s ease-in,visibility .4s ease-in!important;
}
.btn-img{
    border:none;
    background-color: unset;
    padding-left: 0px;
}
.acc-btn{
    border: none;
    background-color: transparent;
    padding: 10px 15px;
}
.acc-btn:hover{
    background-color:darkgrey;
}
.acc-title{
    font-weight: bold;
    padding-top: 15px;
}
@media screen and (max-width: 500px) {
    .img-accessible{
        width: 40px;
    }
}