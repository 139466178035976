.update{
    direction: rtl;
    background-color: aliceblue;
    /* font-family:'Fredoka', sans-serif; */

}
.icon-check{
    color: green;
    font-size: xx-large;
    border: 1px solid;
    border-radius: 50%;
    padding: 5px;
}

@media screen and (max-width: 960px){
    .update{
        padding-top: 10%;

    }
    
   
}

