.popup-box{
    position: fixed;
    top:0px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2 );
    /* font-family:'Fredoka', sans-serif; */
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    z-index: 1;
    overflow: scroll;

}

.popup-inner{
    position: relative;
    padding: 30px;
    /*width: 50%;
    min-height: 300px;*/
    max-width: 640px;
    
    background-color: aliceblue;
    border-radius: 4px;
    margin: 20px auto;
    overflow: auto;
    margin-bottom: 60px;
    padding-top: 40px;
    /* top: 20%; */
}
.close-btn{
    cursor: pointer;
    border: 1px solid #999;
    font-family: 'Suez One', serif;
    position: absolute;
    top: 16px;
    right: 10px;
}