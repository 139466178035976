.allTreat{
    padding-top: 10%;
}
.tTreat{
    direction: rtl;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 50px;
}

@media screen and (max-width: 960px){
    .allTreat{
        padding-top: 20%;
    }
}