.projects{
    padding-top: 10px;
    background-color: ghostwhite;
    direction: rtl;
    justify-content: center;
}
.title-projects{
    text-align: center;
    /* font-family: 'Rubik Dirt', cursive; */
}
.title-more-imgs{
  text-align: center;
  /* font-family: 'Rubik Dirt', cursive; */
}

.slider {
    position: relative;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.container-slider {
    max-width: 300px;
    height: 400px; 

    margin: 10px auto 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  /* @media screen and (max-width: 700px){
    .container-slider {
      margin: 20px 10px 0;
    }
  } */
  .slide {
    width: 100%;
  height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
     width: 100%; 
    height: 100%;
    /* object-fit: cover; */
  }
  .active-anim {
    opacity: 1;
  }
  
  .btn-slide {
    width: 0px;
    height: 0px;
    font-size: 3rem;
   
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px; 
    pointer-events: none;
   
  }
  .prev {
    top: 50%;
    left: 6%;
    transform: translateY(-60%);
    
  }
  .next {
    top: 50%;
    right: 6%;
    transform: translateY(-60%);
  }
  .back-btn{
    background-color: rgb(245, 245, 237);
    border-radius: 50%;
    color: black;
  }
  
  .container-projects{
    padding-bottom: 8rem;
    /* padding-top: 15rem; */
    margin: -90px auto 0px;
  } 
  .gallery-grid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .gallery-card{
    text-align: center;
    font-weight: 700;
    padding-top: 0.75rem;
  }
  .description{
    max-width: 400px;
    display: inline-flex;
}

  @media (min-width: 1024px) {
    .gallery-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}