.manager{
    /* padding-top: 7%; */
    background-color: rgb(232, 246, 247);
    direction: rtl;
    
    
}
.nav-links-logout{
    background: gainsboro;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem ;
    height: 100%;
    font-family:'Fredoka', sans-serif;
    border: 2.5px solid #323d3d;
    border-radius: 10px;
    width: 70px;
    margin-left: 120px;
}
.logout{
   
    padding-left: 50px;
}
.btn-service{
    border: 2px solid #111111;
    text-decoration: none;
    font-family: 'Suez One', serif;
    font-size: 25px;
    margin-left: 70px;
    
}

.add_user{
    direction: rtl;
    padding-right: 30px;
    /*padding: 50px 40px 50px 80px;*/
    padding: 30px;
    box-shadow: 1px 1px 2px rgba((0,0,0,0.125), green, blue, alpha);
    position: relative;
    flex: 1;
    max-width: 500px;
    width: 100%;
    background-color: lightblue;
    margin: 20px auto;
    color: #111111;
   
    text-align: center;
  
}
.grid-projects{
    display: grid;
    grid-template-columns: repeat(3, 30%);
    /* justify-content: center; */
    justify-content: space-evenly;
    justify-items: stretch;
}

.update-projects{
    padding-top: 5%;
}
.form-add_user{
    width: 100%;
}
.input_field{
    margin-bottom: 2px;
    
    align-items: center;
    font-family:'Fredoka', sans-serif;
    font-size: inherit;
}
.title-add {
    margin-bottom: 0px;
}
input{
    /* direction: ltr; */
    outline: none;
    border: 1px solid rgba(135, 85, 221, 0.733);
    font-size: 15px;
    padding: 8px 10px;
    border-radius: 3px;
    transition: all 0.3s ease;
    
}
label{
    width: 200px;
    color: #09080955;
    font-family:'Fredoka', sans-serif ;
    margin-right: 10px;
     /*font-size: 14px; */
    
}

.butt{
    width: 100%;
    padding: 8px 10px;
    font-size: 15px;
    border: 0;
    background: #1717182d;
    color: black;
    cursor:pointer ;
    border-radius: 30px;
    outline: none;
    font-family:'Fredoka', sans-serif;
}
.butt:hover{
    background: #1c1ce02d;
}
.titleUser{
    font-size: 20px;
}
.users-card{
    /* background-color: #639cf1; */
    font-family: 'Suez One', serif;
    text-align: center;
    box-shadow: 1px 10px 10px rgb(0 0 0 / 25%);
    border: 2px solid rgb(115, 125, 125);
    border-radius: 15px;
    margin: 7px;
    /* padding-top: 10px; */
    color: black;
    /* display: flex; 
    justify-content: center;
    align-items: center;*/
    /* border: 1px solid black;
    width: 250px;
    padding: 10px;
    display: inline-grid;
    position: relative; */
}
@media screen and (max-width: 770px){
    .grid-projects{
        grid-template-columns: repeat(1, 60%);
    }
}

@media screen and (max-width: 970px){
    .grid-projects{
        
        padding-left: 20px ;
    }
    .fa-plus-circle{
        font-size: 15px;
    }
    .txt_add{
        font-size: 15px;
    }
    .btn-service {
        font-size: 15px;
    }
    /* .manager{
        padding-top: 15%;
    } */
}

.btn-del{
   width: 60px;
   color: red;
   background-color: rgb(241, 237, 233);
   border: 0;
   position: absolute;
    top: 0px;
    left: 10px;
   
   /* margin-right: 20px; */
    
}
.btn-update{
    width: 60px;
    color: green;
    background-color: rgb(169, 201, 169);
    border: 0;
}
.add_project-btn{
    font-size: 25px;
    font-family: 'Suez One', serif;
    margin-left: 70px;
    display: flex;
    direction: rtl;
    align-items: center;
    justify-content: center;
    align-content: flex-end;
}
.card-projects{
    background: linear-gradient(90deg, rgba(193, 197, 203, 0.788) 0%, rgb(127, 227, 248) 100%);
}
.grid-btn{
    padding-top: 10px;
    /* display: grid;
    grid-template-columns: repeat(2, 30%);
    justify-content: center; */
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    place-items: center;
    grid-template-columns: repeat(2, 30%);
}
@media screen and (max-width: 500px){
    .manager {
        padding-top: 10%;
    }
    .logout{
        font-size: 11px;
    }
    .nav-links-logout{
        width: 50px;
    }
}

@media screen and (max-width: 420px){
    .nav-links-logout{
        margin-left: 0px;
    }
}
