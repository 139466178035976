/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap"); */
@import "react-alice-carousel/lib/alice-carousel.css";

.carousel-in-project .img-gallery{
    width: 350px;
    height: 450px;
}
.div-carousel .img-gallery{
    width: 250px;
    height: 350px;
}
.div-carousel .alice-carousel{
    width: 70%;
}
.alice-carousel__stage-item{
    
    display: inline-grid !important;

    justify-content: space-around;
}
.carousel{
    position: relative;
    border-radius: 10px;
    height: 400px;
    max-width: 750px;
    margin: auto;
    overflow: hidden;
}
.main-carousel{
    width: 50px;
}

.slide_direction{
    display: flex;
    justify-content: space-between;
}
.left, .right{
    background-color: #fdf2f2b5;
    color: #fff;
    /* padding: 10px 8px 8px 13px; */
    margin: 0 20px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 10px;
    height: 25px;
    width: 25px;
}
.left{
    left: 0;
}
.right{
    right: 0;
}
.carousel-indicator{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.icon-three-dots{
    font-size: 190%;
}
th{
    padding: 2px 4px;
    border: 1px solid #ebedf1 !important;
    background-color: #a4c1ff;
  }
  
  td{
    padding: 10px 24px;
    border: 1px solid #a4c1ff !important;
  }