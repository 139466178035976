
.login{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

    
}
.title-login{
    text-align: center;
}
.txt-login{
    text-align: center;
}

.inputs{
    border: solid;
    padding: 30px;
    text-align: center;
    direction: rtl;
    background: #313263;
    border-radius: 10px;
    box-shadow: 10px 50px 20px rgb(0 0 0 / 25%);
    margin-top: 20px;
    margin-bottom: 20%;

}
.input-login{
    text-align: center;
    width: 300px;
    height: 40px;
    margin: 10px;
    /* font-family: 'Noto Sans Hebrew', sans-serif; */
}
.btn-login{
    
    border-radius: 10px;
    width: 150px;
    height: 40px;
    /* font-family: 'Noto Sans Hebrew', sans-serif; */
    bottom: 220px;
    background-color: #bff4ff;
    border: solid;
    

}
.icon-user, .fa-lock{
    color: white;
}
@media screen and (max-width: 1040px){
    .login{
        padding-top: 20%;
    }
}
@media screen and (max-width: 620px){
    .login{
        padding-top: 25%;
    }
}
@media screen and (max-width: 500px){
    .login{
        padding-top: 30%;
    }
}
@media screen and (max-width: 420px){
    .login{
        padding-top: 35%;
    }
}
@media screen and (max-width: 400px){
    .inputs{
        padding: 20px;
    }
    .input-login {
        width: 200px;
    }
}