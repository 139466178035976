.extension{
 
    top: 140px;
    position: fixed;
    width: 250px;
    border: 3px solid #007bff;
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    background-color: rgba(250, 235, 215, 0.459);
    direction: rtl;
    font-family: "Bona Nova SC", serif;
    
}
