.videos{
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}
.title-videos{
  text-align: center;
  /* font-family: 'Rubik Dirt', cursive; */
}
.video{
    width: 653px;
    height: 480px;
    padding-bottom: 20px;
}
@media screen and (max-width: 675px){
    .video{
        width: 350px;
    }
}
